import { api } from '@/services/axios'

export default {
  async getCalendarEventsAction({commit}) {
    await api.get('/user/calendar/events')
    .then((res) => {
        commit('calendarMutation', res.data.data)
    }).catch((err) => {
        console.log(err);
    });
  },
}