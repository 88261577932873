import { api } from '@/services/axios'

export default {
    async getStudentOutputClass({commit}){
        await api.get('/instructor/student-output/class-categories').then(res=>{
            commit('classesMutation', res.data.data)
        }).catch(err => {
            return commit(
              'errorsMutation', 
              err.response.data.errors, 
              { root: true }
            )
          })
    },
    async getStudentOutputCourse({commit}, payload) {
        await api.get('/instructor/student-output/courses', { params: payload }).then(res=>{
            commit('coursesMutation', res.data.data[0].courses)
        }).catch(err => {
            return commit(
                'errorsMutation', 
                err.response.data.errors, 
                { root: true }
            )
        })
    },
    async getStudentOutputModules({commit}, payload) {
        await api.get('/instructor/student-output/modules', { params: payload }).then(res=>{
            commit('modulesMutation', res.data.data)
        }).catch(err => {
            return commit(
                'errorsMutation', 
                err.response.data.errors, 
                { root: true }
            )
        })
    },
    async getStudentOutput({commit}, payload) {
        if(!payload.class_category_id) {
            await api.get('/instructor/student-output').then(res=>{
                commit('studentOutputMutation', res.data.data)
            }).catch(err => {
                return commit(
                    'errorsMutation', 
                    err.response.data.errors, 
                    { root: true }
                )
            })
        } else {
            delete payload.tenant
            await api.get('/instructor/student-output', { params: payload }).then(res=>{
                commit('studentOutputMutation', res.data.data)
            }).catch(err => {
                return commit(
                    'errorsMutation', 
                    err.response.data.errors, 
                    { root: true }
                )
            })
        }
    },
    async getStudentOutputCourseProgress({commit}, payload) {
        await api.get(`/instructor/student-output/course-progress/${payload.course_uuid}`).then(res=>{
            commit('studentOutputUserMutation', { course_progress: res.data.data})
        }).catch(err => {
            return commit(
                'errorsMutation', 
                err.response.data.errors, 
                { root: true }
            )
        })
    },
    async getStudentOutputAssessments({commit}, payload) {
        await api.get(`/instructor/student-output/assessments`, {params: payload}).then(res=>{
            commit('assessmentsMutation', res.data.data)
        }).catch(err => {
            return commit(
                'errorsMutation', 
                err.response.data.errors, 
                { root: true }
            )
        })
    },
    async getStudentOutputOverallGrading({commit}, payload) {
        await api.get(`/instructor/student-output/overall-grading/${payload}`).then(res=>{
            commit('coursesMutation', res.data.data)
        })
    }
}