import { api } from '@/services/axios'

export default {
  async addUserAction({commit, dispatch}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.post('/admin/user-management', payload).then(res => {
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.errors)
      })
    })
  },
  async getUsersAction({commit}, payload) {
    let _query = `/admin/user-management`
    let _payload = []
    if(payload) {
      if(payload.page) {
        _payload.push(`page=${payload.page}`)
        // _query = _query + `?page=${payload.page}&paginate=${payload.paginate}&role=${payload.role}&status=${payload.status}&created_at=${payload.created_at}`
      }
      if (payload.paginate){
        _payload.push(`paginate=${payload.paginate}`)
      }
      if (payload.role) {
        _payload.push(`role=${payload.role}`)
      }
      if (payload.status) {
        _payload.push(`status=${payload.status}`)
      }
      if (payload.created_at) {
        _payload.push(`created_at=${payload.created_at}`)
      }
      if (payload.search) {
        _payload.push(`search=${payload.search}`)
      }
      _query = _query + '?' + _payload.join('&')
    }
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.get(_query).then(res => {
        if(!payload) {
          commit('usersMutation', res.data.data)
        }
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.errors)
      })
    })
  },
  async importUsersAction({commit, dispatch}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.post('/admin/user-management/import/users', payload).then(res => {
        dispatch('getUsersAction')
        resolve(res.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.errors)
      })
    })
  },
  async updateUserDetails({commit, dispatch}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.put(`/admin/user-management/${payload.id}`, payload).then(res => {
        dispatch('getUsersAction')
        resolve(res)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.errors)
      })
    })
  },

  async removeUserCoursesAction({commit, dispatch}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.delete(`/admin/enrollments/${payload.id}`, { user_id: payload.user_id, course_id: payload.course_id}).then(res => {
        dispatch('getUsersAction')
        resolve(res)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.errors)
      })
    })
  }
}