export function coursesMutation(state, payload) {
  state.courses = payload
}

export function completedCoursesMutation(state, payload) {
  state.completed_courses = payload
}

export function objectMutation(state, payload) {
  state.object = payload
}

export function materialMutation(state, payload) {
  state.material = payload
}

export function assessmentsMutation(state, payload) {
  state.assessments = payload
}

export function startAssessmentMutation(state, payload) {
  state.startAssessment = payload
}

export function contactsMutation(state, payload) {
  state.contacts = payload
}

export function messagesMutation(state, payload) {
  state.messages = payload
}

export function chatListsMutation(state, payload) {
  state.chat_lists = payload
}

export function groupListsMutation(state, payload) {
  state.group_lists = payload
}

export function chatWrapperMutation(state, payload) {
  state.chat_wrapper = payload
}

export function calendarMutation(state, payload) {
  state.calendar = payload
}

export function filesMutation(state, payload) {
  state.files = payload
}

export function notificationMutation(state, payload) {
  state.notification = payload
}

export function ticketMutation(state, payload) {
  state.tickets = payload
}

export function meetingMutation(state, payload) {
  state.meeting = payload
}

export function errorsMutation(state, payload) {
  state.errors = payload
}

export function cartMutation(state, payload) {
  state.cart = payload
}

export function transactionHistoryMutation(state, payload) {
  state.transaction_history = payload
}

export function certificatesMutation(state, payload) {
  state.certificates = payload
}