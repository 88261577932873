export default function() {
  return {
    object: [],

    courses: [],
    completed_courses: [],
    contacts: [],
    chat_lists: [],
    group_lists: [],
    messages: [],
    assessments: [],
    
    material: [],

    startAssessment: {
      //call to action end time-over
      action: '',
      status: false
    },

    chat_wrapper: null,
    calendar: [],
    files: [],
    notification: [],
    tickets: [],
    errors: [],
    meeting: null,
    cart: [],

    transaction_history: [],
    certificates: []
  }
}