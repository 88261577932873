import { api } from '@/services/axios'
import { certificatesMutation } from '../mutations';

export default {
  async getCoursesAction({commit}) {
    await api.get('/user/courses')
    .then((res) => {
      commit('coursesMutation', res.data.data)
    }).catch((err) => {
      
    });
  },

  async showCourseAction({commit}, payload) {
    await api.get(`/user/courses/${payload}`)
    .then((res) => {
      let course = res.data.data
      if(course.modules.length>0) {
        course.modules.forEach(module => {
          let data = []
          if(module.topics.length>0) {
            module.topics.forEach(topic => {
              topic['is']='topic'
              data.push(topic)
              if(topic.sub_topics.length>0) {
                topic.sub_topics.forEach(sub => {
                  sub['is']='sub-topic'
                  data.push(sub)
                })
              }
            })
            module['data'] = data
          }
        });  
      }
      commit('objectMutation', res.data.data)
    }).catch((err) => {
      
    });
  },

  async showNextContentAction({commit, dispatch}, payload) {
    await api.post(`/user/course/progress/controls`, payload)
    .then(res => {
      commit('materialMutation', res.data.data)
      dispatch('getCoursesAction')
      // console.log(res.data.data);
    })
    .catch((err) => {
      console.log(err);
    });
  },

  async updateCourseColor({commit, dispatch}, payload) {
    await api.post(`/user/course-card/color`, payload)
    .then(res => {
      dispatch('getCoursesAction')
    }).catch(err => {
      commit('errorsMutation', err.response.data.errors, { 
        root: true 
      })
    })
  },

  async updateCourseFavorite({commit, dispatch}, payload) {
    await api.post(`/user/course/favorite`, payload)
    .then(res => {
      dispatch('getCoursesAction')
    }).catch(err => {
      commit('errorsMutation', err.response.data.errors, { 
        root: true 
      })
    })
  },

  async generateCertificateAction({commit}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.post(`/user/generate/certificate`, payload).then(res => {
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        console.log(err)
        reject(err)
      })
    })
  },

  async getTransactionHistory({commit}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.get(`/user/transaction-history/invoices`, payload).then(res => {
        let _arr = []
        res.data.data.forEach(_course => {
          _arr.push({
            id: _course.id,
            course_id: _course.course.id,
            title: _course.course.title ? _course.course.title : 'Untitled',
            status: _course.status,
            date: _course.updated_at,
            amount: _course.course.price,
            uuid: _course.course.uuid,
            nomination_form: _course.nomination_form,
            official_receipt_no: _course.official_receipt_no
          })
        })
        commit('transactionHistoryMutation', _arr)
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        console.log(err)
        reject(err)
      })
    })
  },

  async uploadProofOfPayment({commit}, payload){
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.post(`/user/upload/nomination-form/invoice/${payload.invoice_id}`, payload.form, { headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
      }}).then(res => {
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        console.log(err)
        reject(err)
      })
    })
  },

  async getCourseCertificatesAction({commit},  payload){
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.get(`/user/certificates`).then(res => {
        commit('certificatesMutation', res.data.data)
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err)
      })
    })
  },

  async downloadCourseCertificateAction({commit},  payload){
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.post(`/user/download/certificate`, payload).then(res => {
        resolve(res.data.downloadable_url)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err)
      })
    })
  },

  async markCourseAsCompletedAction({commit}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.post(`/user/course/mark-as-complete`, payload).then(res => {
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err)
      })
    })
  },

  async getCompletedCoursesAction({commit}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.get(`/user/complete-courses`).then(res => {
        let courses = []
        res.data.data.enrollments.forEach((course) => {
          if(course.course){
            courses.push(course)
          }
        })
        commit('completedCoursesMutation', courses)
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err)
      })
    })
  }
}