import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import middlewarePipeline from './middleware-pipeline'
import { domain } from './domain-middleware'
import { EducatRoutes } from './auth'
import { LandingRoutes } from './landing'
import { TeacherRoutes } from './teacher'
import { UserRoutes } from './user'
import { AdminRoutes } from './admin'
import { SuperRoutes } from './super'

const routes = [
  ...LandingRoutes,
  ...EducatRoutes,
  ...TeacherRoutes,
  ...UserRoutes,
  ...AdminRoutes,
  {
    path: '/forbidden',
    component: () => import('../views/Forbidden.vue'),
  },
  {
    path: '*',
    component: () => import('../views/404.vue')
  },
  {
    path: '/404',
    component: () => import('../views/404.vue')
  },
  {
    path: '/error',
    component: () => import('../views/ServerError.vue')
  },
]

if(window.location.origin == process.env.VUE_APP_MAIN_DOMAIN){
  routes.push(...SuperRoutes)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  
  window.scrollTo(0, 0);
  
  domain({ next })

  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];
    const ctx = { from, next, to };
    const nextMiddleware = middlewarePipeline(ctx, middleware, 1);

    return middleware[0]({ ...ctx, next: nextMiddleware });
  }
  return next();
  
});

export default router
