export default function() {
  return {
    announcements: [],
    courses: [],
    course: [],

    classes: [],
    modules: [],
    student_output: [],
    assessments: [],
    assessment_questions: [],
    assessment_users: [],
    assessment_classes: [],
    course_evaluations: [],
    module_evaluations: [],
    evaluation_answers: [],
    enroll_students: [],
    duplicate_id: [],
    questions: [],
    instructors: [],
    rubrics: [],
    grading: [],
    gradingUsers: [],
    statusCourse: { id: '', status: false },
    trash: [],
    contacts: [],
    
    notification: [],
    chat_lists: [],
    group_lists: [],
    messages: [], 

    meetings: [],
    
    activity_log: {
      show: false,
      popup: false
    }
  }
}