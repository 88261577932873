const routes = [
  {
    subheader: '',
    lists: [
      {
        title: 'Dashboard',
        icon: 'mdi-view-dashboard',
        route: 'Instructor Dashboard'
      },
      {
        title: 'My Courses',
        icon: 'mdi-book-open-outline',
        route: 'Instructor My Courses'
      },
      {
        title: 'Assessment',
        icon: 'mdi-note-edit-outline',
        route: 'Instructor Assessment'
      },
    ]
  },
  {
    subheader: 'USER MANAGEMENT',
    lists: [
      {
        title: 'Enrollments',
        icon: 'mdi-account-clock-outline',
        route: 'Instructor Enrollment'
      },
      {
        title: 'Grading',
        icon: 'mdi-notebook-outline',
        route: 'Instructor Grading'
      },
      {
        title: 'Student Outputs',
        icon: 'mdi-book-open-blank-variant',
        route: 'Instructor Student Outputs'
      },
      {
        title: 'Reporting',
        icon: 'mdi-book-open-page-variant-outline',
        route: 'Instructor Reporting'
      },
    ]
  },
  {
    subheader: 'COMMUNICATION',
    lists: [
      {
        title: 'Announcements',
        icon: 'mdi-forum-outline',
        route: 'Instructor Announcements'
      },
      {
        
        title: 'Inbox',
        icon: 'mdi-message-processing-outline',
        route: 'Instructor Inbox'
      },
    ]
  },
  {
    subheader: '',
    lists: [
      {
        title: 'Trash',
        icon: 'mdi-trash-can-outline',
        route: 'Instructor Trash'
      },
    ]
  },
  // {
  //   subheader: 'COURSE BUILDER',
  //   lists: [
  //     {
  //       title: 'Lesson Manager',
  //       icon: 'mdi-book-open',
  //       route: 'Teacher Lesson Manager'
  //     },
  //     {
  //       title: 'Assessment Manager',
  //       icon: 'mdi-numeric',
  //       route: 'Teacher Assessment Manager'
  //     },
  //     {
  //       title: 'Live Sessions',
  //       icon: 'mdi-video-plus',
  //       route: 'Teacher Live Session'
  //     },
  //   ]
  // },
  // {
  //   subheader: 'HELP DESK',
  //   lists: [
  //     {
  //       title: 'Ticketing Support',
  //       icon: 'mdi-ticket-account',
  //       route: 'Teacher Manage Ticket' 
  //     },
  //     {
  //       title: 'FAQS',
  //       icon: 'mdi-help-circle',
  //       route: ''
  //     },
  //   ]
  // },
  // {
  //   subheader: 'ACCOUNT AND SECURITY',
  //   lists: [
  //     {
  //       title: 'Account Settings',
  //       icon: 'mdi-account',
  //       route: 'Teacher Settings' 
  //     },
  //   ]
  // },
]

export default {
  install(Vue) {
    Vue.prototype.$teacherRoutes = routes
  }
}