import { api } from '@/services/axios'

export default {
  async getContactsAction({commit}) {
    await api.get(`/user/contact/list`)
    .then((res) => {
      commit('contactsMutation', res.data.data)
    }).catch((err) => {
      console.log(err);
    });
  },

  async getChatListsAction({commit}) {
    await api.get(`/user/private/chat/list`)
    .then((res) => {
      commit('chatListsMutation', res.data.data)
    }).catch((err) => {
      console.log(err);
    });
  },

  async showMessagesAction({commit}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.get(`/user/private/chat/${payload}`).then(res => {
        commit('messagesMutation', res.data.data)
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.errors)
      })
    }) 
    // await api.get(`/user/private/chat/${payload}`)
    // .then((res) => {
    //   commit('messagesMutation', res.data.data)
    // }).catch((err) => {
    //   console.log(err);
    // });
  },

  async sendMessageAction({commit, dispatch}, payload) {
    await api.post(`/user/private/chat/send`, payload)
    .then((res) => {
      // console.log(res.data.data.uuid);
      dispatch('showMessagesAction', res.data.data)
      // commit('messagesMutation', res.data.data)
    }).catch((err) => {
      console.log(err);
    });
  },

  async addGroupAction({commit, dispatch}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.post(`/user/group/chat`, payload).then(res => {
        dispatch('getGroupListsAction')
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.errors)
      })
    })
  },

  async getGroupListsAction({commit, dispatch}, payload) {
    return await new Promise((resolve, reject) => {
      api.get(`/user/group/chat/list`, payload).then(res => {
        commit('groupListsMutation', res.data.data)
        resolve(res)
      }).catch((err) => {
        reject(err);
      });
    })
    // await api.get(`/user/group/chat/list`, payload)
    // .then((res) => {
    //   // console.log(res.data);
    //   commit('groupListsMutation', res.data.data)
    // }).catch((err) => {
    //   console.log(err);
    // });
  },

  async showGroupChatAction({commit, dispatch}, payload) {
    return await new Promise((resolve, reject) => {
      api.post(`/user/group/chat/${payload.id}`).then(res => {
        commit('groupListsMutation', res.data.data)
        resolve(res)
      }).catch((err) => {
        reject(err);
      });
    })
    // await api.get(`/user/group/chat/${payload}`)
    // .then((res) => {
    //   commit('groupListsMutation', res.data.data)
    // }).catch((err) => {
    //   console.log(err);
    // });
  },

  async updateGroupPhotoAction({commit, dispatch}, payload) {
    return await new Promise((resolve, reject) => {
      api.post(`/user/group/chat/photo/${payload.id}`, payload.form).then(res => {
        dispatch('showGroupChatAction', payload.id)
        dispatch('getGroupListsAction')
        resolve(payload.id)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err)
      })
    })
  },

  async removeGroupPhotoAction({commit, dispatch}, payload) {
    return await new Promise((resolve, reject) => {
      api.delete(`/user/group/chat/photo/${payload.id}/remove`).then(res => {
        dispatch('showGroupChatAction', payload.id)
        dispatch('getGroupListsAction')
        resolve(payload.id)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err)
      })
    })
  },

  async addUsersGroupAction({commit, dispatch}, payload) {
    await api.post(`/user/add/users/group/chat/${payload.group_chat_uuid}`, payload)
    .then((res) => {
      dispatch('showGroupChatAction', payload.group_chat_uuid)
      dispatch('getGroupListsAction')
      //commit('messagesMutation', res.data.data)
    }).catch((err) => {
      console.log(err);
    });
  },

  async sendGroupChatAction({commit, dispatch}, payload) {
    await api.post(`/user/group/chat/send`, payload)
    .then((res) => {
      console.log(res.data);
      dispatch('showGroupChatAction', payload.uuid)
      // commit('messagesMutation', res.data.data)
    }).catch((err) => {
      console.log(err);
    });
  },

  async showGroupChatAction({commit, dispatch}, payload) {
    await api.get(`/user/group/chat/${payload}`, )
    .then((res) => {
      console.log(res.data);
      commit('messagesMutation', res.data.data)
    }).catch((err) => {
      console.log(err);
    });
  },
}