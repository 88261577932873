import { api } from '@/services/axios'

export default {
  async addModuleAction({commit, dispatch}, payload) {
    await api.post('/instructor/modules', payload)
    .then(res => {
      //dispatch('getCourseModulesAction', payload.course_id)
      dispatch('updateCourseUpdatedAt', payload.course_id )
      dispatch('showCourseAction', payload.course_id)
      // dispatch('showCourseAction', payload.course_id)
    })
    .catch(err => {
      commit(
        'errorsMutation', 
        err.response.data.errors, 
        { root: true }
      )
    })
    
  },
  async getModulesAction({commit}) {
    await api.get('/instructor/modules')
    .then(res => {
      commit('classesMutation', res.data.data)
    })
    .catch(err => {
      return commit(
        'errorsMutation', 
        err.response.data.errors, 
        { root: true }
      )
    })
  },

  async updateModuleAction({commit, dispatch}, payload) {
    await api.put(`/instructor/modules/${payload.module_id}`, payload)
    .then(res => {
      dispatch('updateCourseUpdatedAt', res.data.data.course_id )
      dispatch('showCourseAction', payload.course_id)
    })
    .catch(err => {
      return commit(
        'errorsMutation', 
        err.response.data.errors, 
        { root: true }
      )
    })
  },

  async sortModuleAction({commit, dispatch}, payload) {
    await api.put(`/instructor/module/sort`, payload)
    .then(res => {
      dispatch('updateCourseUpdatedAt', payload.course_id )
      dispatch('showCourseAction', payload.course_id)
      console.log(res.data);
    })
    .catch(err => {
      return commit(
        'errorsMutation', 
        err.response.data.errors, 
        { root: true }
      )
    })
  },

  async moduleValidityAction({commit, dispatch}, payload) {
    await api.put(`/instructor/module/${payload.id}/validity`, payload)
    .then(res => {
      dispatch('updateCourseUpdatedAt', payload.course_id )
      dispatch('showCourseAction', payload.course_id)
      // dispatch('showCourseAction', payload.course_id)
    })
    .catch(err => {
      return commit(
        'errorsMutation', 
        err.response.data.errors, 
        { root: true }
      )
    })
  },

  async deleteModuleAction({commit, dispatch}, payload) {
    await api.delete(`/instructor/modules/${payload.id}`)
    .then(res => {
      dispatch('updateCourseUpdatedAt', payload.course_id )
      dispatch('showCourseAction', payload.course_id)
      // dispatch('showCourseAction', payload.course_id)
    })
    .catch(err => {
      return commit(
        'errorsMutation', 
        err.response.data.errors, 
        { root: true }
      )
    })
  },

  async showModulePrerequisiteAction({commit, dispatch}, payload) {
    await api.get(`/instructor/modules/${payload}`)
    .then(res => {
      commit('modulesMutation', res.data.data)
      dispatch('updateCourseUpdatedAt', payload )
      dispatch('showCourseAction', payload.course_id)
    })
    .catch(err => {
      return commit(
        'errorsMutation', 
        err.response.data.errors, 
        { root: true }
      )
    })
  },

  async addModulePrerequisiteAction({commit, dispatch}, payload) {
    await api.post(`/instructor/module/sync/prerequisites`, payload)
    .then(res => {
      console.log(res.data);
      dispatch('updateCourseUpdatedAt', payload.course_id )
    })
    .catch(err => {
      return commit(
        'errorsMutation', 
        err.response.data.errors, 
        { root: true }
      )
    })
  },

  async addModuleEvaluationAction({commit, dispatch}, payload) {
    commit('moduleEvaluationsMutation', payload)
  },

  async updateModuleEvaluationQuestionAction({commit}, payload) {
    commit('evaluationQuestionMutation', payload)
  },

  async updateModuleEvaluationAction({commit, dispatch}, payload) {
    commit('moduleEvaluationsMutation', payload)
  },

  async deleteModuleEvaluationAction({commit, dispatch}, payload) {
    commit('moduleEvaluationsMutation', payload)
  },

} 