import { api } from '@/services/axios'

export default {
  async addToCart({commit, dispatch}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.post(`/user/add-to-cart`, payload).then(res => {
        dispatch('getCartItems')
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.errors)
      })
    })
  },
  async getCartItems({commit}) {
    await api.get('/user/add-to-cart/items')
    .then((res) => {
        commit('cartMutation', res.data.data)
    }).catch((err) => {
        console.log(err);
    });
  },
  async removeFromCart({dispatch}, payload) {
    await api.post('/user/remove-from-cart', payload)
    .then((res) => {
        dispatch('getCartItems')
    }).catch((err) => {
        console.log(err);
    });
  },
  async emptyCart({commit}) {
    await api.post('/user/empty-cart')
    .then((res) => {
        commit('cartMutation', [])
    }).catch((err) => {
        console.log(err);
    });
  },
}