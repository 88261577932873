import { api } from '@/services/axios'

export default {
  async getTicketsAction({commit}, payload) {
    commit('errorsMutation', [], { root: true })

    return await new Promise((resolve, reject) => {
      api.get(`/admin/tickets?category=${payload.category}&paginate=${payload.paginate}&page=${payload.page}`)
        .then(res => {
          res.data.data.data.data.forEach(ticket => {
            if(ticket.user) {
              ticket.submitted_by = `${ticket.user.first_name ? ticket.user.first_name : 'Learner'} ${ticket.user.last_name ? ticket.user.last_name : 'Account'} ${ticket.user.suffix ? ticket.user.suffix : ''}`
            }
          });
          commit('ticketsMutation', res.data.data.data.data)
          commit('ticketCountMutation', res.data.data.count)
          resolve(res.data.data.data)
        })
      })
      .catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err)
      })
  },

  async getTicketCountAction({commit}, payload){
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.get(`/admin/ticket-count`)
        .then(res => {
          resolve(res.data.data)
        })
      })
      .catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err)
      })
  }
}