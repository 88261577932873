<template>
  <v-app id="app">
    <router-view/>
  </v-app>
</template>

<style lang="scss">
  @import "~ag-grid-community/styles/ag-grid.css";
  @import "~ag-grid-community/styles/ag-theme-alpine.css";
</style>

<script>
import { mapMutations } from 'vuex'
import axios from './services/axios'

// const sessionTimeoutDuration = 20000; // 20 seconds

// let sessionTimer;
// let lastActivityTime = Date.now();

// function resetSessionTimer() {
//   clearTimeout(sessionTimer);
//   sessionTimer = setTimeout(logoutUser, sessionTimeoutDuration);
// }

// function logoutUser() {
//   alert("Your session has timed out. You will be logged out now.");
// }

// function onUserActivity() {
//   lastActivityTime = Date.now();
//   resetSessionTimer();
// }

// document.addEventListener("mousemove", onUserActivity);
// document.addEventListener("keypress", onUserActivity);

// resetSessionTimer();

export default {
  data: () => ({
    images: {},
    image_keys: ['horizontal_logo', 'vertical_logo', 'sidebar_logo', 'login_logo', 'favicon', 'side_carousel'],
    color_keys: ['navigation_background_color', 'navigation_banner_color', 'navigation_link_color', 'navigation_text_icon_color', 'navigation_link_inactive_color', 'sidebar_color', 'mini_navigation_background_color', 'mini_navigation_link_color', 'mini_navigation_icon_color', 'mini_navigation_inactive_link_color', 'primary_buttons_color']
  }),
  mounted(){
    document.title =  process.env.VUE_APP_NAME;
    
    let _customization = localStorage.getItem('customization')
    let _organization = localStorage.getItem('organization')

    if(_organization) {
      this.tenantMutation(localStorage.getItem('organization'))
    }
    if(_customization) {
      this.customizationMutation(JSON.parse(_customization))      
    }

    if (!_customization || !_organization) {
      let axiosInstance = axios.create({
        baseURL: process.env.VUE_APP_TENANT_API
      })

      axiosInstance.defaults.headers.common = {
        'X-Requested-With': 'XMLHttpRequest',
        'Access-Control-Allow-Origin': '*'
      }
      axiosInstance.get('tenants/info').then(res => {

        if(!_organization) {
          localStorage.setItem('organization', res.data.data.info.organization)
        }

        this.image_keys.forEach(key => {
          if(key === 'side_carousel'){
            res.data.data.images.filter(_item => _item.image_key.includes('side_carousel')).forEach((_item, key) => {
              this.images[key] = _item
            })
          } else if(res.data.data.images.findIndex(_item => _item.image_key === key) != -1) {
            this.images[key] = res.data.data.images.find(_item => _item.image_key === key).url
          } else {
            this.images[key] = null
          }
        })

        let custom_string = `{`

        this.image_keys.forEach(key => {
          if(key === 'side_carousel'){
            this.images.filter(_item => _item.image_key.includes('side_carousel')).forEach((_item, key) => {
              custom_string = custom_string.concat(`"${key}": "${this.images[key]}",`)
            })
          } else if(this.images[key]) {
            custom_string = custom_string.concat(`"${key}": "${this.images[key]}",`)

            if(key === 'favicon'){
              var favicon = document.querySelector('link[rel="icon"][sizes="16x16"]');
              favicon.href = this.images[key]
              var favicon = document.querySelector('link[rel="icon"][sizes="32x32"]');
              favicon.href = this.images[key]
            }
          }
        })

        this.color_keys.forEach(key => {
          if(res.data.data.hasOwnProperty(key)) {
            if(res.data.data[key] !== '' || !res.data.data[key]) {
              custom_string = custom_string.concat(`"${key}": "${this.color_keys[key]}",`)
            }
          }
        })

        custom_string = custom_string.concat(`}`)
        
        if(Object.keys(JSON.parse(custom_string)).length !== 0) {
          localStorage.setItem('customization', JSON.stringify(custom_string) )
          this.customizationMutation(JSON.parse(custom_string))
        }
      })
    }
  },

  methods: {
    ...mapMutations(['tenantMutation', 'customizationMutation']),
  }
}
</script>


