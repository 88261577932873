import { api } from '@/services/axios'

export default {
  async enrollStudentAction({commit, dispatch}, payload) {
    commit('errorsMutation', [], { root: true })
    const config = { 
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
      }
    }
    return await new Promise((resolve, reject) => {
      api.post('/instructor/manual/enrollments', payload, config)
      .then(() => {
        dispatch('getEnrollStudentAction')
        resolve('yes')
      }).catch(err => {
        commit('errorsMutation', err.response.data.errors, { 
          root: true 
        })
        reject(err.response.data.errors)
      })
    })

    // await api.post('/instructor/manual/enrollments', payload, config)
    // .then(res => {

    // })
    // .catch(err => {
    //   commit('errorsMutation', err.response.data.errors, { 
    //     root: true 
    //   })
    // })
  },
  async getEnrollStudentAction({commit, dispatch}, payload) {
    await api.get('/instructor/manual/enrollments', payload)
    .then(res => {
      commit('enrollStudentsMutation', res.data.data)
    })
    .catch(err => {
      commit('errorsMutation', err.response.data.errors, { 
        root: true 
      })
    })
  },
}