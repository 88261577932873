import state from './state'
import * as getters from './getters'
import * as mutations from './mutations'
import user from './actions/user'
import courses from './actions/courses'
import classes from './actions/classes'
import registration from './actions/registration'
import enrollment from './actions/enrollment'
import tickets from './actions/tickets'

export default {
  namespaced: true,
  mutations,
  getters,
  state,
  actions: {
    ...user,
    ...courses,
    ...classes,
    ...registration,
    ...enrollment,
    ...tickets
  }
}