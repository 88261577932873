import store from '@/store'

function middleware({ next }) {
  let obj = JSON.parse(localStorage.getItem('access_payload'))
  
  if (!obj || !obj.token) {
    if(window.location.pathname.includes('super-admin')) return next('/super-admin/login')
    return next('/login')
  }

  store.dispatch('authAction').then(() => {
    if(!store.getters.userGetters.email_verified_at) return next ('/unverified')
    if(store.getters.userGetters.role=='USER') return
    if(store.getters[`userGetters`].hasOwnProperty('role')) {
      if(!window.location.pathname.includes(store.getters[`userGetters`].role.toLowerCase())) return next('/forbidden') 
    }
  })
  
  return next()
}

export {
  middleware,
}