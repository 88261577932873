import { middleware } from './middleware'

export const AdminRoutes = [
  {
    path: '/admin',
    component: () => import('../layouts/Main.vue'),
    redirect: { name: 'Admin Dashboard' },
    children: [
      {
        path: '/admin/dashboard',
        name: 'Admin Dashboard',
        component: () => import('../views/admin/Dashboard.vue'),
        meta: {
          childname: 'Admin Dashboard',
          collapse: false,
          title_append: '',
          middleware: [middleware]
        }
      },
      {
        path: '/admin/user-management',
        name: 'Admin User Management',
        component: () => import('../views/admin/UserManagement.vue'),
        meta: {
          childname: 'Admin User Management',
          collapse: false,
          title_append: '',
          middleware: [middleware]
        }
      },
      {
        path: '/admin/enrollment',
        name: 'Admin Course Enrollment',
        component: () => import('../views/admin/enrollment/CourseEnrollment.vue'),
        meta: {
          childname: 'Admin Course Enrollment',
          collapse: false,
          title_append: '',
          middleware: [middleware]
        },
      },
      {
        path: '/admin/enrollments/:course_uuid',
        name: 'Admin User Enrollment',
        component: () => import('../views/admin/enrollment/CourseStudentsEnrollment.vue'),
        meta: {
          parent: 'Admin Course Enrollment',
          collapse: false,
          title_append: '',
          middleware: [middleware]
        }
      },
      {
        path: '/admin/class-category',
        name: 'Admin Class Category',
        component: () => import('../views/admin/ClassCategory.vue'),
        meta: {
          childname: 'Admin Class Category',
          collapse: false,
          title_append: 'Customization',
          middleware: [middleware]
        }
      },
      {
        path: '/admin/ticket',
        name: 'Admin Ticket',
        component: () => import('../views/admin/Ticket.vue'),
        meta: {
          childname: 'Admin Ticket',
          collapse: false,
          title_append: '',
          middleware: [middleware]
        }
      },
      {
        path: '/admin/registration',
        name: 'Admin Registration',
        component: () => import('../views/admin/Registration.vue'),
        meta: {
          childname: 'Admin Registration',
          collapse: false,
          title_append: 'Customization',
          middleware: [middleware]
        }
      },
      {
        path: '/admin/account-settings',
        name: 'Admin Settings',
        component: () => import('../views/admin/AccountSettings.vue'),
        redirect: {name: 'Admin Account Profile'},
        meta: {
          childname: 'Admin Settings',
          collapse: false,
          title_append: '',
          middleware: [middleware],
        },
        children: [
          {
            path: 'profile',
            name: 'Admin Account Profile',
            component: () => import('../views/user/Profile.vue'),
            meta: {
              parent: 'Admin Settings',
              collapse: false,
              middleware: [middleware],
            },
          },
          {
            path: 'change-password',
            name: 'Admin Change Password',
            component: () => import('../views/user/ChangePassword.vue'),
            meta: {
              parent: 'Admin Settings',
              collapse: false,
              middleware: [middleware],
            },
          }
        ]
      },
    ]
  }
]
