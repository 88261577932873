import { api } from '@/services/axios'

export default {
  async getInstructorReportingStats({dispatch}, payload) {
    return await new Promise((resolve, reject) => {
      api.get(`/instructor/report/list/cards`).then(res => {
        resolve(res.data.data)
      }).catch(err => {
        reject(err.response.data.errors)
      })
    })
  },

  async getInstructorCoursesReportingStats({dispatch}, payload) {
    return await new Promise((resolve, reject) => {
      api.get(`/instructor/report/list/courses`).then(res => {
        resolve(res.data.data)
      }).catch(err => {
        reject(err.response.data.errors)
      })
    })
  },

  async getInstructorEnrolledUsersAction({dispatch}, payload) {
    return await new Promise((resolve, reject) => {
      api.get(`/instructor/report/list/course/${payload}/users`).then(res => {
        resolve(res.data.data)
      }).catch(err => {
        reject(err.response.data.errors)
      })
    })
  },

  async getRegistrationFieldsAction(){
    return await new Promise((resolve, reject) => {
      api.get(`/instructor/report/custom-registration/field/lists`).then(res => {
        resolve(res.data.data)
      }).catch(err => {
        reject(err.response.data.errors)
      })
    })
  },
  
  async getRegistrationFilterAction(){
    return await new Promise((resolve, reject) => {
      api.get(`/instructor/report/custom-registration/field/lists`).then(res => {
        resolve(res.data.data)
      }).catch(err => {
        reject(err.response.data.errors)
      })
    })
  },

  async getInstructorFilteredEnrolledUsersAction({dispatch}, payload){
    return await new Promise((resolve, reject) => {
      api.get(`/instructor/report/total-users-per-course`, {params: { ...payload }} ).then(res => {
        resolve(res.data.data)
      }).catch(err => {
        reject(err.response.data.errors)
      })
    })
  }
}