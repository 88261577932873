import { api } from "@/services/axios"

export default {
    async getGradingAction({commit}, payload) {
        await api.post(`/instructor/grading/check-assessment-answers`, payload)
        .then(res => {
            commit('gradingMutation', res.data.data)
        });
    },
    async updateGradingAction({dispatch}, payload){
        await api.put('/instructor/grading/update/score', payload)
        .then(res=> {
            console.log(res.data.data)
            dispatch('getGradingAction', {
                assessment_id: payload.assessment_id,
                user_id: payload.user_id
            })
        })
    },
    async getGradingStudentList({commit}, payload){
        await api.get(`/instructor/grading/assessment/${payload}`)
        .then(res => {
            commit('gradingMutation', res.data.data)
            commit('gradingAssessmentUsersMutation', res.data.data.assessment_scores)
        })
    },
    async getGradingAssessment({commit}, payload){
        await api.get(`/instructor/grading/${payload}`)
        .then(res => {
            commit('gradingMutation', res.data.data)
        })
    },
    async getGrading({commit}, payload) {
        await api.get(`/instructor/grading`).then( res =>{
            commit('gradingMutation', res.data.data)
        })
    },
    async getGradingStudent({commit}, payload) {
        await api.get(`/instructor/grading/${payload}`)
        .then(res =>{
            commit('gradingAssessmentUsersMutation', res.data.data.assessments)
        })
    }
}