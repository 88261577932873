import { api } from '@/services/axios'

export default {
  async getInstructorAssessmentsAction({commit}) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.get('/instructor/assessments').then(res => {
        commit('assessmentsMutation', res.data.data)
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.errors)
      })
    })
  },

  async addAssessmentAction({commit, dispatch}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.post('/instructor/assessments', payload).then(res => {
        dispatch('getAssessmentsAction', {course_id: payload.course_id})
        dispatch('updateCourseUpdatedAt', payload.course_id )
        dispatch('showCourseAction', payload.course_id)
        resolve(res)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.errors)
      })
    })
  },

  async updateAssessmentAction({commit, dispatch}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.put(`/instructor/assessments/${payload.id}`, payload).then(res => {
        dispatch('updateCourseUpdatedAt', payload.course_id )
        dispatch('showCourseAction', payload.course_id)
        resolve(res)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.errors)
      })
    })
  },

  async getAssessmentsAction({commit}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.get(`/instructor/course/${payload.course_id}/assessments`).then(res => {
        commit('assessmentQuestionsMutation', res.data.data)
        resolve(res)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.errors)
      })
    })
  },

  async deleteAssessmentAction({commit, dispatch}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.delete(`/instructor/assessments/${payload.id}`).then(res => {
        dispatch('showCourseAction', payload.course_id )
        dispatch('getInstructorAssessmentsAction')
        dispatch('showCourseAction', payload.course_id)
        resolve(res)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.errors)
      })
    })
  },

  async moveAssessmentAction({commit, dispatch}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.put(`/instructor/assessment/${payload.assessment_id}/move-to-course`, payload).then(res => {
        dispatch('getInstructorAssessmentsAction')
        dispatch('showCourseAction', payload.course_id)
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.errors)
      })
    })
    // await api.put(`/instructor/assessment/${payload.assessment_id}/move-to-course`, payload)
    // .then(res => {
    //   dispatch('getInstructorAssessmentsAction')
    //   dispatch('showCourseAction', payload.course_id)
    // })
    // .catch(err => {
    //   return commit(
    //     'errorsMutation', 
    //     err.response.data.errors, 
    //     { root: true }
    //   )
    // })
  },

  async getTagClassAssessmentAction({commit, dispatch}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.get(`/instructor/assessment/${payload.id}/user-access-tags`).then(res => {
        commit('assessmentUsersMutation', res.data.data)
        resolve(res)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.errors)
      })
    })
  },

  async tagClassAssessmentAction({commit, dispatch}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.put(`/instructor/assessment/${payload.id}/user-access-tags`, payload).then(res => {
        // dispatch('updateCourseUpdatedAt', payload.id )
        dispatch('updateCourseUpdatedAt', payload.course_id )
        resolve(res)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.errors)
      })
    })
  },

  async showTaggedUsersAction({commit, dispatch}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.get(`/instructor/course/${payload.course_id}/assessment/${payload.id}/class-enrollee`, payload).then(res => {
        commit('assessmentClassesMutation', res.data.data[0].class_categories)
      // let _user_id = []
      // res.data.data.class_categories.forEach(classes => {
      //   classes.enrollments.forEach(element => {
      //     _user_id.push(element.user_id)
      //   });
      // })
      //commit('assessmentUsersMutation', res.data.data[0].class_categories)
      //dispatch('tagClassAssessmentAction', {id: payload.id, course_id: payload.course_id, user_id: _user_id})
        resolve(res)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.errors)
      })
    })
  },
  

  async duplicateAssessmentAction({commit}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.post(`/instructor/assessment/${payload.assessment_id}/duplicate`).then(res => {
        commit('duplicateIdMutation', res.data.data)
        resolve(res)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.errors)
      })
    })
  },
  

  async getInstructorsToShareAction({commit}) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.get(`/instructor/assessment/share-to-instructors`).then(res => {
        commit('instructorsMutation', res.data.data)
        resolve(res)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.errors)
      })
    })
  },

  async shareAssessmentAction({commit}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.post(`/instructor/assessment/${payload.assessment_id}/share`, payload).then(res => {
        //commit('instructorsMutation', res.data.data)
        resolve(res)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.errors)
      })
    })
  },

  async showSharedToOtherAction({commit}) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.get(`/instructor/assessment/shared-to-others`).then(res => {
        commit('assessmentsMutation', res.data.data)
        resolve(res)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.errors)
      })
    })
  },

  async showSharedWithMeAction({commit}) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.get(`/instructor/assessment/shared-with-me`).then(res => {
        commit('assessmentsMutation', res.data.data)
        resolve(res)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.errors)
      })
    })
  },

  async moveAssessmentSharedWithMeAction({commit}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.post(`/instructor/assessment/shared-with-me/move`, payload).then(res => {
        // commit('instructorsMutation', res.data.data)
        resolve(res)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.errors)
      })
    })
  },
  
}